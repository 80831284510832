// Imports
// ------
import React from 'react';
import Layout from '@parts/Layout';

// Component
// ------
const NotFoundPage = () => (
	<Layout>
		NOPE!
	</Layout>
);

export default NotFoundPage;
